import {Component, OnInit} from '@angular/core';
/* import {UtilService} from '../app/shared/services/util.service'; */

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    
    enviropment: string;
    message: string;
    constructor(/* private utilSrv: UtilService */){ }
    ngOnInit() { 
/*         switch(this.utilSrv.currentURL) {
            case 'pti.clinica.local':
                this.enviropment = 'homolog';
                this.message = 'Você está logado em um servidor de homologação'
                break;
            case 'localhost':
                this.enviropment = 'develop';
                this.message = 'Você está logado em um servidor de Desenvolvimento'
                break;
            default:
                this.enviropment = 'production';
        } */
    }
}
