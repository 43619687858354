import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: 'site', 
        loadChildren: () => import('./site/site.module').then(m => m.SiteModule)        
      },
    { path: 'users', 
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)      
    },
    { path: 'config', 
        loadChildren: () => import('./config/config.module').then(m => m.ConfigModule)         
    },
    { path: 'relatorio', 
        loadChildren: () => import('./relatorio/relatorio.module').then(m => m.RelatorioModule)         
    },
    { path: 'importar', 
        loadChildren: () => import('./importar/importar.module').then(m => m.ImportarModule)       
    },
    { path: 'cliente', 
        loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule)   
    },
    { path: '', 
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)     
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/**
   { path: 'print-ficha', 
    loadChildren: () => import('./print-ficha/print-ficha.module').then(m => m.PrintFichaPageModule) , 
    canActivate: [AuthGuard], data: {roles: rolesImpressao} },
 */