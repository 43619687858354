import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
// import { UtilService } from "../app/shared/services/util.service";

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        //UtilService,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
